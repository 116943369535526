import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Guide`}</h2>
    <p>{`(Gatsby Carbon MDX Guide)`}{`[https://gatsby-theme-carbon.now.sh/guides/MDX]`}</p>
    <h2>{`Task`}</h2>
    <p>{`This should start with a resource description…`}</p>
    <p><inlineCode parentName="p">{`This is some important note.`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`GET https://api.video.ibm.com/channels/{channel_id}.json
`}</code></pre>
    <h3>{`Parameters`}</h3>
    <p>{`This request has no parameters.`}</p>
    <p><inlineCode parentName="p">{`-OR-`}</inlineCode></p>
    <p>{`The query parameters for the GET request:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`page`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Requested page number (value is 1 by default)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pagesize`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Requested page size (value is 50 by default, max. 50)`}</td>
        </tr>
      </tbody>
    </table>
    <p><inlineCode parentName="p">{`-OR-`}</inlineCode></p>
    <p>{`The parameters for the PUT request:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`param1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`param2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Success response`}</h3>
    <p>{`For this request an empty response is returned.`}</p>
    <p><inlineCode parentName="p">{`-OR-`}</inlineCode></p>
    <p>{`Upon success, a response with HTTP status ”`}<inlineCode parentName="p">{`200 OK / 201 Created / 202 Accepted / 204 No Content`}</inlineCode>{`” is returned.`}</p>
    <p><inlineCode parentName="p">{`-OR-`}</inlineCode></p>
    <p>{`Upon success a response with HTTP status “200 OK” is returned with the following key-value pairs under a `}<inlineCode parentName="p">{`channel`}</inlineCode>{` key.`}</p>
    <p><inlineCode parentName="p">{`-OR-`}</inlineCode></p>
    <p>{`Upon success a response with HTTP status “200 OK” is returned with an array under a `}<inlineCode parentName="p">{`captions`}</inlineCode>{` key. The elements of the array contain the following key-value pairs for each `}<inlineCode parentName="p">{`caption`}</inlineCode>{`:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`KEY`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`key1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`key2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`key3`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`key4`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A set of key-value pairs containing information about `}<inlineCode parentName="td">{`key4 settings`}</inlineCode>{` with the following attributes: `}<inlineCode parentName="td">{`child1`}</inlineCode>{`, `}<inlineCode parentName="td">{`child2`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`key4.child1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`key4.child2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`key5`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <p>{`Example of a success response:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{ "success": true }
`}</code></pre>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`unauthorized`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not Found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`not_found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel was not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>
    <p><inlineCode parentName="p">{`(^ minimal) – OR – (with all possible codes v )`}</inlineCode></p>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`400 Bad Request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`input_validation_error`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Validation failed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invalid_token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`402 Payment Required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invalid_request`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The user does not have the benefit`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403 Forbidden`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lack_of_ownership`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The API user is not allowed to manage the given channel`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not Found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`not_found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel was not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Site metadata</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Manifest</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Favicon</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Additional font weights</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Image compression</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Global search</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Edit on Github link</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Medium</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Other options</AnchorLink>
    </AnchorLinks>
    <h2>{`Site metadata`}</h2>
    <p>{`To add a title and description to each page, simply provide them to siteMetadata in your `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode>{` file.`}</p>
    <p>{`The language attribute applied to the `}<inlineCode parentName="p">{`<html>`}</inlineCode>{` tag on every page is English (`}<inlineCode parentName="p">{`en`}</inlineCode>{`) by default, but you can choose to override this. For more information on declaring the language of a page in HTML, please review `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/language-of-page"
      }}>{`W3 Criterion 3.1.1 Language of a Page`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`module.exports = {
  siteMetadata: {
    title: 'Gatsby Theme Carbon',
    description: 'A Gatsby theme for the carbon design system',
    keywords: 'gatsby,theme,carbon',
    lang: 'en',
  },
  plugins: ['gatsby-theme-carbon'],
};
`}</code></pre>
    <h2>{`Manifest`}</h2>
    <p>{`One of the first configurations should be to override the default manifest options, you can do this in `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode>{`. Any options you don’t set, will be provided by the theme. See the example project.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`siteMetadata: {
    title: 'Gatsby Theme Carbon',
  },
  plugins: [
    'gatsby-theme-carbon',
    {
      resolve: 'gatsby-plugin-manifest',
      options: {
        name: 'Carbon Design Gatsby Theme',
        short_name: 'Gatsby Theme Carbon',
        start_url: '/',
        background_color: '#ffffff',
        theme_color: '#0062ff',
        display: 'browser',
      },
    },
  ],
`}</code></pre>
    <h2>{`Favicon`}</h2>
    <p>{`If you need to override the default favicon, you can do so by passing a relative path to the icon. It’s recommended to provide a 512 x 512 version.`}</p>
    <p><strong parentName="p">{`IMPORTANT:`}</strong>{` For best results, if you’re providing an icon for generation it should be…`}</p>
    <ul>
      <li parentName="ul">{`at least as big as the largest icon being generated (512x512 by default).`}</li>
      <li parentName="ul">{`square (if it’s not, transparent bars will add to make it square).`}</li>
      <li parentName="ul">{`of one of the follow formats: JPEG, PNG, WebP, TIFF, GIF or SVG.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`plugins: [
    {
      resolve: 'gatsby-theme-carbon',
      options: {
        iconPath: './src/images/custom-icon-512.png'
      },
    },
  ],
`}</code></pre>
    <h2>{`Additional font weights`}</h2>
    <p>{`If needed, you can add support for additional Plex font weights. Don’t forget to specify italics for the additional weights if needed.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`plugins: [
    {
      resolve: 'gatsby-theme-carbon',
      options: {
        // will get added to default [300, 400, 600]
        additionalFontWeights: ['200', '200i]
      },
    },
  ],
`}</code></pre>
    <h2>{`Image compression`}</h2>
    <p>{`You can enable WebP by passing `}<inlineCode parentName="p">{`withWebp: true`}</inlineCode>{` or providing your own optimization level. See the gatsby-remark-images `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/packages/gatsby-remark-images/#options"
      }}>{`plugin options`}</a>{`. You can also tweak the image quality based on design tolerance and performance thresholds.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`module.exports = {
  plugins: [
    {
      resolve: 'gatsby-theme-carbon',
      options: {
        withWebp: true, // true, false (default false)
        imageQuality: 50, // 0-100 (default 75)
      },
    },
  ],
};
`}</code></pre>
    <h2>{`Global search`}</h2>
    <p>{`Site-wide search is provided by the theme. The only requirement for a page to show up in the results is for it to have `}<inlineCode parentName="p">{`title`}</inlineCode>{` set in the `}<a parentName="p" {...{
        "href": "/components/MDX#frontmatter"
      }}>{`frontmatter`}</a>{`.
To render more helpful search results (and improve SEO), you’ll want to make sure your pages have `}<inlineCode parentName="p">{`description`}</inlineCode>{` set in the frontmatter as well.`}</p>
    <p>{`Global search is enabled by default. To disable it, set the `}<inlineCode parentName="p">{`isSearchEnabled`}</inlineCode>{` option to false.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  plugins: [
    {
      resolve: 'gatsby-theme-carbon',
      options: {
        isSearchEnabled: false
      },
    },
  ],
`}</code></pre>
    <p>{`Under the hood, we use `}<a parentName="p" {...{
        "href": "https://lunrjs.com/"
      }}>{`Lunr`}</a>{` to create our search index. If necessary, you tweak the search scoring algorithm and source nodes.
To do so, provide your own `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/packages/gatsby-plugin-lunr/#getting-started"
      }}>{`resolvers object`}</a>{` to the `}<inlineCode parentName="p">{`lunrOptions`}</inlineCode>{` theme option.`}</p>
    <h2>{`Edit on GitHub link`}</h2>
    <p>{`To add a link to the bottom of each page that points to the current page source in GitHub, provide a `}<inlineCode parentName="p">{`repository`}</inlineCode>{` object to `}<inlineCode parentName="p">{`siteMetadata`}</inlineCode>{` in your `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode>{` file. You can provide a `}<inlineCode parentName="p">{`baseUrl`}</inlineCode>{`, and if needed, the `}<inlineCode parentName="p">{`subDirectory`}</inlineCode>{` and `}<inlineCode parentName="p">{`branch`}</inlineCode>{` where your site source lives.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  plugins: [
    {
      resolve: 'gatsby-theme-carbon',
      options: {
        repository: {
          baseUrl: 'https://github.com/carbon-design-system/gatsby-theme-carbon',
          subDirectory: '/packages/example',
          branch: 'master',
        },
      },
    },
  ],
`}</code></pre>
    <h2>{`Medium`}</h2>
    <p>{`In order to change the source account for the `}<a parentName="p" {...{
        "href": "/components/MediumPosts"
      }}><inlineCode parentName="a">{`MediumPosts`}</inlineCode></a>{` component, provide an account with the `}<inlineCode parentName="p">{`mediumAccount`}</inlineCode>{` option.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`plugins: [
  {
    resolve: 'gatsby-theme-carbon',
    options: {
      mediumAccount: 'carbondesign',
    },
  },
];
`}</code></pre>
    <h2>{`Other options`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`additionalFontWeights`}</inlineCode>{` – add support for additional Plex font weights. Don’t forget to specify italics for the additional weights if needed.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`mdxExtensions`}</inlineCode>{` – change the file extensions processed by `}<inlineCode parentName="p">{`gatsby-mdx`}</inlineCode>{` (default `}{`[‘.mdx’, ‘.md’]`}{`).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`pngCompressionSpeed`}</inlineCode>{` - a speed/quality trade-off from 1 (brute-force) to 10 (fastest). Speed 10 has 5% lower quality, but is 8 times faster than the default (4).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`titleType`}</inlineCode>{` – pick between four formats for the `}<inlineCode parentName="p">{`<title>`}</inlineCode>{` element for your site. Here are the four options using this page as an example:`}</p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`page`}</inlineCode>{`: “Configuration” (default)`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`site`}</inlineCode>{`: “Gatsby Theme Carbon”`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`append`}</inlineCode>{`: “Gatsby Theme Carbon – Configuration”`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`prepend`}</inlineCode>{`: “Configuration - Gatsby Theme Carbon”`}</li>
        </ul>
      </li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`plugins: [
    {
      resolve: 'gatsby-theme-carbon',
      options: {
        additionalFontWeights: ['200', '200i'],
        mdxExtensions: ['.mdx'],
        titleType: 'append'
      },
    },
  ],
`}</code></pre>
    <h2>{`Additional remark plugins and override existing remark plugin configuration`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`gatsbyRemarkPlugins`}</inlineCode>{` - An array containing gatsby remark plugin configurations to be added/overridden.`}</li>
    </ul>
    <h3>{`Example `}<a parentName="h3" {...{
        "href": "https://www.gatsbyjs.org/packages/gatsby-remark-mermaid/"
      }}>{`Gatsby Remark Mermaid plugin`}</a></h3>
    <p>{`For the below markdown snippet:`}</p>
    <pre><code parentName="pre" {...{}}>{`\`\`\`mermaid
graph LR
install[Install Plugin]
install --> configure[Configure Plugin]
configure --> draw[Draw Fancy Diagrams]
\`\`\`
`}</code></pre>
    <p>{`to turn into an image one should add the following configuration to her/his own project:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{` plugins: [
    {
      resolve: 'gatsby-theme-carbon',
      options: {
        repository: {
          baseUrl: 'https://github.com/carbon-design-system/gatsby-theme-carbon',
          subDirectory: '/packages/example',
          branch: 'master',
        },
        gatsbyRemarkPlugins: [
          {
            resolve: 'gatsby-remark-mermaid',
            options: {
              language: 'mermaid',
              theme: 'dark',
              viewport: {
                width: 200,
                height: 200,
              },
              mermaidOptions: {
                themeCSS: '.node rect { fill: cornflowerblue; }',
              },
            },
          },
        ],
      },
    },
  ],

`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      